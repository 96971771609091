<template>
  <v-date-picker v-model="localDate" mode="date">
    <template v-slot="{ inputValue, togglePopover }">
      <div class="date__wrapper">
        <button @click="togglePopover({ placement: 'auto-start' })">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="">
            <path
              fill="#3182ce"
              d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
            ></path>
          </svg>
        </button>
        <input
          :value="inputValue"
          class="bg-white text-gray-700 w-full py-1 px-1"
          readonly
        />
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import moment from "moment/moment";
export default {
  name: "BaseSingleDate",
  data() {
    return {
      localDate: moment().format(),
    };
  },
  watch: {
    localDate: {
      handler(n) {
        this.$emit("change", n);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.date__wrapper {
  display: flex;
  align-items: center;
  font-size: 13px;
  input {
    outline: none;
    border: 1px solid #bee3f8;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  button {
    color: #3182ce;
    padding: 0.4rem;
    border-width: 1px;
    border-style: none;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-color: #bee3f8;
    background-color: #bee3f8;
  }
  button:focus {
    outline: none;
  }
  svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }
}
</style>
