<template>
  <div>
    <input
      type="text"
      :value="value"
      v-on="listeners"
      @keypress="$emit('keypress', $event)"
      v-bind="$attrs"
      :placeholder="placeholder"
      class="input"
      :style="{ width: inputWidth, 'text-align': textAlign }"
    />
  </div>
</template>
<script>
export default {
  name: "BaseTinyInput",
  props: {
    value: {
      type: [String, Number],
      description: "Input value",
    },
    placeholder: {
      type: String,
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      inputWidth: `${this.placeholder.length * 9}px`,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
        keyup: this.onKeyUp,
      };
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
    onKeyUp() {
      this.inputWidth =
        this.value.length * 8 >= 100
          ? `${this.value.length * 8}px`
          : `${this.placeholder.length * 9}px`;
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  height: 18px;
  font-size: 13px;
  border: none;
  outline: none;
}

.input:focus {
  border-width: 1px;
  background-color: #f2f8fd;
  border-radius: 1px;
  box-shadow: 0 0 0 2px #f2f8fd, 0 0 0 4px #cdd4d9;
}
</style>
