<template>
  <div style="display: flex; align-items: center;">
    <slot name="label">
      <label v-if="label" class="toggle-label mr-2">
        {{ label }}
      </label>
    </slot>
    <label class="custom-toggle">
      <input
        type="checkbox"
        v-model="model"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <span class="custom-toggle-slider rounded-circle"></span>
    </label>
  </div>
</template>
<script>
export default {
  name: "base-switch",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "Switch value",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
.toggle-label {
  font-size: 0.775rem;
}
</style>
