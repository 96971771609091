export default {
  methods: {
    isNumber: function(
      event,
      isWhole = false,
      limit = 0,
      allowNegative = false
    ) {
      if (event.keyCode === 32) event.preventDefault();
      let pValue = event.target.value;
      let position = event.target.selectionStart;
      let value = [
        pValue.slice(0, position),
        event.key,
        pValue.slice(position),
      ].join("");
      if (allowNegative) {
        if (value === "-") {
          return "-";
        }
        if (value !== "-" && isNaN(value)) {
          event.preventDefault();
        }
      }
      if ((isNaN(value) || value < 0) && !allowNegative) {
        event.preventDefault();
      }
      if (!allowNegative && event.key === "-") {
        event.preventDefault();
      }
      if (isWhole && event.key === ".") {
        event.preventDefault();
      }
      if (!isWhole) {
        let rounded = Math.round(value * 100) / 100;
        // fix for the round trimming
        if (event.key === ".") {
          rounded += event.key;
        }
        if (value.slice(-2) === ".0") {
          rounded += ".0";
        }
        if (rounded.toString() !== value.toString()) {
          event.preventDefault();
        }
      }
      if (limit > 0 && parseInt(value).toString().length > limit) {
        event.preventDefault();
      }
    },
  },
};
