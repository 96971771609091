var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Icons',
          icon: 'ni ni-planet text-blue',
          path: '/icons',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Maps',
          icon: 'ni ni-pin-3 text-orange',
          path: '/maps',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'User Profile',
          icon: 'ni ni-single-02 text-yellow',
          path: '/profile',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Tables',
          icon: 'ni ni-bullet-list-67 text-red',
          path: '/tables',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Login',
          icon: 'ni ni-key-25 text-info',
          path: '/admin/login',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Register',
          icon: 'ni ni-circle-08 text-pink',
          path: '/register',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Company',
          icon: 'ni ni-shop text-green',
          path: '/create-company',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Invoices',
          icon: 'ni ni-paper-diploma text-red',
          path: '/invoices',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Items & Services',
          icon: 'fa fa-tags text-purple',
          path: '/items-and-services',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Expenses',
          icon: 'fas fa-pizza-slice text-pink',
          path: '/expenses',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }