import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
Vue.use(Router);

export default new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: DashboardLayout,
      children: [
        {
          path: "",
          redirect: "dashboard",
        },
        {
          path: "dashboard",
          name: "dashboard",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
        },
        {
          path: "icons",
          name: "icons",
          component: () =>
            import(/* webpackChunkName: "icons" */ "./views/Icons.vue"),
        },
        {
          path: "profile",
          name: "profile",
          component: () =>
            import(/* webpackChunkName: "profile" */ "./views/UserProfile.vue"),
        },
        {
          path: "maps",
          name: "maps",
          component: () =>
            import(/* webpackChunkName: "maps" */ "./views/Maps.vue"),
        },
        {
          path: "tables",
          name: "tables",
          component: () =>
            import(/* webpackChunkName: "tables" */ "./views/Tables.vue"),
        },
        {
          path: "create-company",
          name: "createCompany",
          component: () =>
            import(
              /* webpackChunkName: "createCompany" */ "./views/CreateCompany.vue"
            ),
        },
        {
          path: "invoices",
          name: "invoices",
          component: () =>
            import(
              /* webpackChunkName: "invoices" */ "./views/Invoices/ViewInvoices.vue"
            ),
        },
        {
          path: "invoices/create-invoice",
          name: "createInvoice",
          component: () =>
            import(
              /* webpackChunkName: "createInvoice" */ "./views/Invoices/CreateInvoice.vue"
            ),
        },
        {
          path: "invoices/:id",
          name: "invoicePdf",
          component: () =>
            import(
              /* webpackChunkName: "invoicePdf" */ "./views/Invoices/InvoicePdf.vue"
            ),
        },
        {
          path: "items-and-services",
          name: "itemsAndServices",
          component: () =>
            import(
              /* webpackChunkName: "createInvoice" */ "./views/ItemsAndServices/view/ViewItems.vue"
            ),
        },
        {
          path: "items-and-services/create",
          name: "createItem",
          component: () =>
            import(
              /* webpackChunkName: "createInvoice" */ "./views/ItemsAndServices/create/CreateItem.vue"
            ),
        },
        {
          path: "expenses",
          name: "viewExpenses",
          component: () =>
            import(
              /* webpackChunkName: "viewExpenses" */ "./views/Expenses/ViewExpenses.vue"
            ),
        },
        {
          path: "expenses/create",
          name: "createExpense",
          component: () =>
            import(
              /* webpackChunkName: "createExpenses" */ "./views/Expenses/CreateExpense.vue"
            ),
        },
      ],
    },
    {
      path: "/admin",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "login",
          name: "login",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Login.vue"),
        },
        {
          path: "register",
          name: "register",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Register.vue"),
        },
      ],
    },
  ],
});
