<template>
  <div
    class="base-image-input"
    :style="{ 'background-image': `url(${imageData})`, height: height }"
    @click="chooseImage"
  >
    <i
      @click.prevent.stop="imageData = null"
      v-show="imageData"
      class="ni ni-fat-remove fat-remove text-red"
    ></i>
    <span v-if="!imageData" class="placeholder">
      <div class="slot-text">
        <slot></slot>
      </div>
    </span>
    <input
      class="file-input"
      ref="fileInput"
      type="file"
      @input="onSelectFile"
    />
  </div>
</template>

<script>
export default {
  name: "BaseImageInput",
  props: {
    height: {
      type: String,
      default: `200px`,
    },
  },
  data() {
    return {
      imageData: null,
    };
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("image-selected", files[0]);
      }
    },
  },
};
</script>

<style>
.base-image-input {
  border: 2px solid #5ece8a;
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.slot-text {
  max-width: 85%;
  font-size: 0.9rem;
}
.file-input {
  display: none;
}
.fat-remove {
  font-size: 1.5rem;
}
</style>
